<template>
    <div class="page">

        <Head />
        <Sidebar />
        <div class="body-main">
            <breadInfo />
            <div class="container bg flex-1 flex-v">
                <div class="pub-tt m15">
                    <h5 class="bt">
                        <img src="../assets/images/equip_01.png" class="ico" alt="" />角色列表
                    </h5>
                    <div class="dix"></div>
                </div>
                <div class="p025 top-bar">
                    <button class="ann annbg3" @click="onAdd">创建新角色</button>
                </div>
                <div class="flex-1 flex-v p025">
                    <div class="table flex-1">
                        <el-table :data="tableData" height="100%" v-loading="loading"
                            element-loading-background="rgba(0, 0, 0, 0.8)">
                            <el-table-column prop="id" width="60" label="序号">
                            </el-table-column>
                            <el-table-column prop="name" label="角色" width="260">
                            </el-table-column>
                            <el-table-column prop="remarks" label="角色说明">
                            </el-table-column>
                            <el-table-column prop="menusZh" label="菜单权限">
                            </el-table-column>
                            <el-table-column prop="lastEditUserName" label="最后修改人">
                            </el-table-column>
                            <el-table-column prop="lastEditTime" label="最后修改时间">
                            </el-table-column>
                            <el-table-column label="操作" width="150">
                                <template slot-scope="scope">
                                    <span class="lk c-main" @click="handleEdit(scope.$index, scope.row)">编辑</span>
                                    <span class="lk c-red" @click="handleDelete(scope.$index, scope.row)">删除</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <el-pagination @current-change="handleCurrentChange" v-model:current-page="pageInfo.pageNum"
                        layout="prev, pager, next, jumper,total" :total="total" :page-size="pageInfo.pageSize">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 编辑角色 -->
        <el-dialog :visible.sync="addRolePop" width="45%">
            <div class="pop pop-addrole">
                <div class="toptt">
                    <span class="backbtn">{{ roleTitle }}</span>
                    <i class="el-icon-close close" @click="addRolePop = false"></i>
                    <div class="dix"></div>
                </div>
                <div class="detail">
                    <el-form ref="roleForm" :model="addRoleForm" :rules="roleForm" label-width="95px">
                        <el-row :gutter="10">
                            <el-col :span="24">
                                <el-form-item label="角色名称:" prop="name">
                                    <el-input v-model="addRoleForm.name" placeholder="请输入姓名"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :span="12">
                                <el-form-item label="备注:" prop="remarks">
                                    <el-input v-model="addRoleForm.remarks" placeholder="请输入备注"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" :offset="1">
                                <el-form-item label="菜单授权:" :rules="{
                        required: true,
                        message: '菜单权限不能为空',
                        trigger: 'change',
                    }">
                                    <el-checkbox-group v-model="menusRule" @change="menuChange">
                                        <el-checkbox v-for="(item, index) in roleMenuData" :key="index"
                                            :label="item.id">{{
                        item.name }}</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="btngroup">
                            <button class="ann confirm" @click.prevent="onSubmit">
                                确定
                            </button>
                            <button class="ann back" @click="addRolePop = false">返回</button>
                        </div>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import { roleList, delRoleById, addRole, editRole, listRole } from "@/api/role";
import breadInfo from "@/components/breadInfo.vue";
export default {
    data() {
        return {
            form: {
                equip: "",
                biaoji: "",
                name: "",
                desc: "",
                level: "",
            },
            pageInfo: {
                pageSize: 10,
                pageNum: 1,
            },
            total: 0,
            tableData: [], //角色表格信息
            addRolePop: false,
            roleTitle: "",
            addRoleForm: {
                id: "",
                name: "",
                remarks: "",
                menus: "",
                menusZh: "",
            },
            menusRule: [],
            loading: false,
            roleForm: {
                name: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
                remarks: [
                    { required: true, message: "请输入角色备注", trigger: "blur" },
                ],
            },
            roleMenuData: [],
        };
    },
    components: {
        Head,
        Sidebar,
        breadInfo,
    },
    created() {
        this.getRoleList();
    },
    mounted() { },
    watch: {},
    methods: {
        menuChange(val) {
            this.menusRule = val;
            let menuInfo = this.menusRule.join(",");
            this.addRoleForm.menus = menuInfo;
            var roleListZh = [];
            for (let i = 0; i < val.length; i++) {
                roleListZh.push(this.roleMenuData[val[i] - 1].name);
            }
            this.addRoleForm.menusZh = roleListZh.join(",");
        },
        /**
         * 查询角色信息
         */
        getRoleList() {
            this.loading = true;
            roleList(this.pageInfo).then((res) => {
                if (res.data.code == 0) {
                    this.loading = false;
                    this.tableData = res.data.rows;
                    this.total = res.data.total;
                }
            });
        },
        //添加设备弹窗
        onAdd() {
            this.editId = -1;
            this.roleTitle = "新增角色";
            this.addRolePop = true;
            (this.addRoleForm = {
                id: "",
                name: "",
                remarks: "",
                menus: "",
                menusZh: "",
            }),
                (this.menusRule = []);
            this.getRoleMenu();
        },
        getRoleMenu() {
            let param = {
                pageNum: 1,
                pageSize: 99999,
            };
            listRole(param).then((res) => {
                if (res.data.code == 0) {
                    this.roleMenuData = res.data.rows;
                }
            });
        },
        //新增角色
        onSubmit() {
            var param = this.addRoleForm;
            delete param.menusZh;
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$refs.roleForm.validate((valid) => {
                if (valid) {
                    if (this.editId == -1) {
                        addRole(param).then((res) => {
                            if (res.data.code != 0) {
                                loading.close();
                                this.$message.warning(res.data.msg);
                            } else {
                                this.addRolePop = false;
                                loading.close();
                                this.getRoleList();
                            }
                        });
                    } else {
                        this.addRoleForm.id = this.editId;
                        editRole(param).then((res) => {
                            if (res.data.code != 0) {
                                loading.close();
                                this.$message.warning(res.data.msg);
                            } else {
                                this.addRolePop = false;
                                loading.close();
                                this.getRoleList();
                            }
                        });
                    }
                }
            });
        },
        /**
         * 编辑角色
         */
        handleEdit(index, row) {
            this.addRoleForm.name = row.name;
            this.addRoleForm.remarks = row.remarks;
            this.addRoleForm.menus = row.menus.split(",").map((el) => Number(el));
            this.addRoleForm.menusZh = row.menusZh.split(",");
            this.editId = row.id;
            this.menusRule = this.addRoleForm.menus;
            this.roleTitle = "编辑角色";
            this.addRolePop = true;
            this.getRoleMenu();
        },
        /**
         * 删除用户
         * @param {*} index
         * @param {*} row
         */
        handleDelete(index, row) {
            this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.tableData.splice(index, 1);
                    this.deleteRole(row.id);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        //删除具体用户
        deleteRole(id) {
            let param = {
                ids: String(id),
            };
            this.loading = true;
            delRoleById(param).then((res) => {
                if (res.data.code == 0) {
                    this.loading = false;
                    this.$message({
                        type: "success",
                        message: "删除成功!",
                    });
                } else {
                    this.$message.warning(res.data.msg);
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                }
            });
        },
        /**
         *
         * @param {*} val
         */
        handleCurrentChange(val) {
            this.pageInfo.pageNum = val;
            this.getRoleList();
        },
    },
};
</script>

<style scoped></style>
